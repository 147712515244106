<template>
  <div class="spinner-container">
    <svg width="200" height="200" viewBox="0 0 200 200" color="#3f51b5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" :stop-color="pink" stop-opacity="0" />
          <stop offset="100%" :stop-color="orange" stop-opacity="0.5" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" :stop-color="pink" stop-opacity="1" />
          <stop offset="100%" :stop-color="orange" stop-opacity="0.5" />
        </linearGradient>
      </defs>

      <g stroke-width="8">
        <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
        <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
      </g>
    </svg>
  </div>
</template>

<script setup>
const pink = '#e901be'
const orange = '#f46d4e'
</script>

<style scoped>
.spinner-container {
  width: 100%;
  height: auto;
  max-width: 200px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

svg {
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  animation: rotate 1.3s linear infinite;
}
</style>
