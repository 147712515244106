<template>
  <div>
    <BlackBoxToolCard :card-name="tr('Clinical Timeline')" :payload="payload"></BlackBoxToolCard>
  </div>
</template>

<script setup>
import BlackBoxToolCard from '../BlackBoxToolCard/BlackBoxToolCard.vue'
import { clinicalTimelineBaseUrl } from '@/settings'
const tr = (x) => x // inject('tr')

const payload = {
  url: {
    baseUrl: clinicalTimelineBaseUrl,
    method: 'GET',
    queryParams: {
      currentThemeName: { key: 'ux2_theme', required: false },
      language: { key: 'language', required: true },
      patientId: { key: 'MRN', required: true },
      toolId: { key: 'toolId', required: true },
    },
  },
}
</script>

<style lang="scss" scoped></style>
