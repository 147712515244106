<template>
  <div class="overlay" @click="$emit('close-modal')"></div>
  <div class="modal-container card-elevated">
    <div class="card-header" :class="{ 'spinner': isLoading }">
      <div class="header-text">Manage external users</div>
      <span class="material-symbols-outlined close" @click="$emit('close-modal')">close</span>
    </div>
    <div class="modal-body">
      <div class="heading">
        <p class="heading-content">External users can be invited or removed from the {{ tumorBoardName }} Tumor Board
          taking place on {{
    tumorBoardDate }}.</p>
      </div>
      <div class="add-button-container">
        <button class="search-physician primary-filled-button" @click="isInviteModalVisible = true">
          <i class="material-symbols-outlined">add</i> <span>Add User</span>
        </button>
      </div>
      <!-- TODO: Styles for this DataTable are currently set in PhysicianScheduleAdmin.vue. This is confusing.
       Styles that are used across components should be declared in a centralized location. -->
      <div class="scheduled-physicians-div">
        <DataTable v-if="!externalUsers || externalUsers.length === 0">
          <template #empty>
            <p class="no-records-class"> No external users have been invited to this Tumor Board session. Click 'Add
              User'
              to invite users.</p>
          </template>
          <Column field="name" header="Name" sortable></Column>
          <Column field="email" header="Email" sortable></Column>
        </DataTable>
        <DataTable v-else :value="externalUsers" paginator editMode="cell" tableStyle="min-width: 10rem"
          :alwaysShowPaginator=false tableClass="editable-cells-table" :rows=5>
          <Column field="caregiver_name" header="Name" sortable>
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column field="caregiver_email" header="Email">
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column>
            <template #body="rowData">
              <span class="material-symbols-outlined icon-button-to-primary size-20 icon-delete" title="Delete Row"
                @click="openConfirmation(rowData.data)">cancel</span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <InviteExternalUsersForm v-if="isInviteModalVisible" :name="tumorBoardName" :date="tumorBoardDate"
      @close-modal="isInviteModalVisible = false" @send-external-invite="sendExternalInvite" />
    <ConfirmationModal v-if="isConfirmationModalVisible" :description="confirmationDescription"
      :header="confirmationHeader" :confirmationCallback="handleDelete"
      @close-confirmation="isConfirmationModalVisible = false" />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import InviteExternalUsersForm from '@/ux3/components/ExternalUsers/InviteExternalUsersForm.vue'
import ConfirmationModal from '@/ux3/components/ConfirmationModal/ConfirmationModal.vue'
import { createExternalUser, deleteExternalUserFromEvent, getExternalUsersEvent, inviteExternalUser } from '@/ux3/apis/externalUser.js'
import { updateCalendarInvite } from '@/ux3/apis/updateCalendarInvite.js'
import { showToast } from '@/utils/utils.js'

defineEmits(['close-modal', 'send-external-invite'])

const props = defineProps({
  allExternalUsers: {
    type: Array,
    required: true,
  },
  calendarId: {
    type: String,
    required: true,
  },
  tumorBoardDate: {
    type: String,
    required: true,
  },
  tumorBoardId: {
    type: String,
    required: true,
  },
  tumorBoardName: {
    type: String,
    required: true,
  },
})

const isInviteModalVisible = ref(false)
const isConfirmationModalVisible = ref(false)
const userToDelete = ref({})
const externalUsers = ref([])
const isLoading = ref(false)

const confirmationHeader = 'Remove User'
const confirmationDescription = 'Are you sure you want to remove this user from the Tumor Board session?'

const getUserId = async (data) => {
  const existingUser = props.allExternalUsers.value.find(user => user.email === data.email)

  if (existingUser) {
    return existingUser.external_attendee_id
  }

  const payload = {
    name: data.name,
    email: data.email,
  }
  const userId = (await createExternalUser(payload)).result
  return userId
}

const sendExternalInvite = async (data) => {
  isLoading.value = true
  isInviteModalVisible.value = false

  const newUserId = await getUserId(data)
  const payload = {
    calendarId: props.calendarId,
    uuid: newUserId,
  }

  const resp = await inviteExternalUser(payload)
  if (resp.status === 200) {
    showToast('success', `${data.name} has been successfully invited to the tumor board.`)
  } else if (resp.status === 409) {
    showToast('error', `A user with email: ${data.email} has already been added to this tumor board meeting.`)
  } else {
    showToast('error', `There was a problem inviting ${data.name} to the tumor board`)
  }
  await getAndSaveExternalUsers()
  isLoading.value = false
}

const getAndSaveExternalUsers = async () => {
  // Get calendar id for clicked event pass into this component then pass to this new get request
  isLoading.value = true
  const response = await getExternalUsersEvent(props.calendarId)
  externalUsers.value = response.result
  isLoading.value = false
}

const handleDelete = async () => {
  isConfirmationModalVisible.value = false
  isLoading.value = true

  const payload = {
    calendarId: props.calendarId,
    uuid: userToDelete.value.external_attendee_id,
  }
  const delResp = await deleteExternalUserFromEvent(payload)

  if (delResp.status === 200) {
    showToast('success', `${userToDelete.value.name} has been successfully deleted from the tumor board.`)
    const getResp = await getExternalUsersEvent(props.calendarId)
    externalUsers.value = getResp.result

    await updateCalendarInvite(props.tumorBoardId)
  } else {
    showToast('error', `There was a problem deleting ${userToDelete.value.name} from the tumor board`)
  }
  userToDelete.value = {}
  isLoading.value = false
}

const openConfirmation = (data) => {
  isConfirmationModalVisible.value = true
  userToDelete.value = data
}

onMounted(async () => {
  await getAndSaveExternalUsers()
})
</script>

<style lang="scss" scoped>
.heading-content {
  color: var(--Grey600);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 300;
}

// TODO: remove unused classes
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
}

.modal-container {
  position: absolute;
  top: 5%;
  left: 10%;
  z-index: 1000;
  background-color: var(--White);
  color: var(--Black);
  width: 80vw;
  height: 80vh;
  margin: auto;
}

.header-text {
  font-size: 1rem;
  color: var(--Grey800);
}

.modal-body {
  padding: 1.25rem 3rem;
}

.close {
  cursor: pointer;
}

.search-physician {
  margin-right: 0;
}

.error {
  color: var(--ErrorRedDark)
}

.add-button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
