<template>
  <InputText type="text" v-model="localTumorBoardName" class="input-text" placeholder="Enter Name" @input="handleInput"
    @blur="onBlurInput(localTumorBoardName)" />
  <div v-if="errorMessage" class="error-message-div">
    <p>{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import { useStore } from 'vuex'
import InputText from 'primevue/inputtext'

const emit = defineEmits(['update-name-error-message', 'update-tumor-board-name'])

const props = defineProps({
  errorMessage: {
    type: String,
    default: '',
  },
  tumorBoardName: {
    type: String,
    default: '',
  },
  originalName: {
    type: String,
    default: '',
  },
})

const store = useStore()
const tumorBoards = computed(() => store.state.session?.tumorBoardTypes?.tb_types)
const localTumorBoardName = ref(props.tumorBoardName)

const handleInput = (event) => {
  localTumorBoardName.value = event.target.value
}

const hasDuplicate = (value) => {
  const trimmedValue = value.trim()

  const isDuplicateNamePresent = tumorBoards.value.find(element => element.tb_name.trim() === trimmedValue)
  return !!isDuplicateNamePresent
}

const onBlurInput = (name) => {

  emit('update-tumor-board-name', localTumorBoardName.value)
  const newErrorMessage = validateInput(name)
  if (newErrorMessage) {
    emit('update-name-error-message', newErrorMessage)
  } else if (name && hasDuplicate(name) && name !== props.originalName) {
    emit('update-name-error-message', 'This Tumor Board name already exists')
  } else {
    emit('update-name-error-message', '')
  }
}

const validateInput = (value) => {
  const trimmedValue = value?.trim()
  if (!trimmedValue) { return 'Input value cannot be empty' }
  if (trimmedValue.length > 100) { return 'Input cannot exceed 100 characters' }
  return ''
}

watch(() => props.tumorBoardName, (newVal) => {
  localTumorBoardName.value = newVal
})
</script>

<style lang="scss" scoped>
.error-message-div p {
  color: var(--ErrorRedDark);
}

.input-text {
  background-color: var(--White);
}
</style>
